<template>
  <div class="tianshine">
    <beacon-banner
      :bannerSrc="banner_7"
      title="时刻把握食品动态   为你我安全保驾护航"
      subTitle="news  information"
    ></beacon-banner>
    <div class="content">
      <div class="section section_1">
        <img class="desc_img" :src="tianshine_logo" alt="" />
        <div class="desc_text">
          天食安秉持“天食安食更安”的宗旨，通过数据流程化处理和实时图片识别的技术手段对项目现场操作进行规范化、标准化，提供覆盖食安
          现场操作全流程的智慧食安管控解决方案，实现多层级智能预警食安现场管控的创新模式。天食安智慧食安云平台自2019年5月上线以来，已经
          应用在612家学校、
          机关及企事业单位，受到了各级政府的密切关注和高度认可，赢得了社会公众的高度共鸣和赞扬。
        </div>
      </div>

      <div class="section section_2">
        <div class="title">为什么做天食安</div>
        <div class="section_content">
          <div class="img_wrap">
            <img class="why_img" :src="tianshine_1" alt="" />
          </div>
          <div class="why_text">
            <div class="why_title">传统食安管理模式下危机状况？</div>
            <div class="why_content">
              <ul class="crisis_list">
                <li class="crisis_item">
                  A、大量的手工记录，真实性和及时性不足
                </li>
                <li class="crisis_item">B、项目上人工巡检，人为因素干扰严重</li>
                <li class="crisis_item">C、监管方实地巡查无法有效全覆盖</li>
                <li class="crisis_item">D、家长对校园食堂的天然的不信任感</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="section section_3">
        <div class="title">天食安的优势</div>
        <div class="section_content">
          <ul class="advantage_list">
            <li class="advantage_item">
              <div class="advantage_content">
                <img class="advantage_img" :src="advantage_1" alt="" />
                <div class="advantage_title">控标准</div>
                <div class="desc">建立统一的规范，让食安管控体系标准化。</div>
              </div>
            </li>
            <li class="advantage_item">
              <div class="advantage_content">
                <img class="advantage_img" :src="advantage_2" alt="" />
                <div class="advantage_title">控证照</div>
                <div class="desc">
                  证照齐全合规，临期超期自 动预警，食材来源可追溯。
                </div>
              </div>
            </li>
            <li class="advantage_item">
              <div class="advantage_content">
                <img class="advantage_img" :src="advantage_3" alt="" />
                <div class="advantage_title">控流程</div>
                <div class="desc">
                  27项食堂现场操作流程全覆 盖，避免管理漏洞。
                </div>
              </div>
            </li>
            <li class="advantage_item">
              <div class="advantage_content">
                <img class="advantage_img" :src="advantage_4" alt="" />
                <div class="advantage_title">控监管</div>
                <div class="desc">
                  云端巡检+项目自检+监管可 视化指挥，把控数据，直达 现场。
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="section section_5">
        <div class="title">天食安的架构</div>
        <div class="section_content">
          <div class="img_wrap">
            <img src="@/assets/images/tianshine_2.png" alt="" />
          </div>
        </div>
      </div>
      <div class="section section_4">
        <div class="title">天食安的客户</div>
        <div class="section_content">
          <ul class="customer_list">
            <li
              class="customer_item"
              v-for="(customer, index) in customerList"
              :key="index"
            >
              <div class="bg_white">
                <img class="customer_img" :src="customer.img" alt="" />
                <div class="customer_title">{{ customer.title }}</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  banner_7,
  tianshine_logo,
  tianshine_1,
  advantage_1,
  advantage_2,
  advantage_3,
  advantage_4
} from "@/utils/images";
export default {
  data() {
    return {
      banner_7,
      tianshine_logo,
      tianshine_1,
      advantage_1,
      advantage_2,
      advantage_3,
      advantage_4,
      customerList: [],
      customerTitle: [
        "中国科学技术大学",
        "安徽理工大学",
        "黄山大厦",
        "重庆三峡学院",
        "天河湖餐饮",
        "西南大学",
        "重庆师范大学",
        "李府酒店",
        "海南师范大学",
        "佳木斯大学",
        "好日子",
        "重庆工程学院",
        "重庆科技学院",
        "重庆韵涵",
        "安徽职业技术学院",
        "合肥职业技术学院",
        "济南真诚餐饮",
        "宿州职业技术学院",
        "金菜园",
        "重庆财经职业学院",
      ],
    };
  },
  created() {
    for (let index = 1; index <= 20; index++) {
      this.customerList.push({
        img: require("@/utils/images")[`customer_tianshine_${index}`],
        title: this.customerTitle[index - 1],
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.section_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5.2% 10.4% 12.18%;
  .desc_img {
    width: 127px;
    height: 127px;
    flex-shrink: 0;
  }
  .desc_text {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    line-height: 28px;
    color: #666666;
    margin-left: 6.57%;
  }
}

.section_2 {
  .title {
    padding-top: 0;
  }
  .section_content {
    display: flex;
    align-items: center;
    padding-bottom: 0;
    .img_wrap {
      width: 63%;
      overflow: hidden;
      border-radius: 0;
      align-self: stretch;
    }
    .why_img {
      object-fit: contain;
    }
    .why_text {
      flex: 1;
      margin-left: 2.94%;
      background-color: #f5f8fa;
      align-self: stretch;
      flex: 1;
      padding: 2.08rem 3.04rem 0 2.08rem;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
    }
    .why_title {
      font-size: 1.25rem;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 2.08rem;
    }

    .why_content {
      flex: 1;
      display: flex;
      align-items: center;
      padding: 4rem 0 4rem;
    }

    .crisis_list {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 1.6rem;
      color: #333333;
      .crisis_item:not(:first-child) {
        margin-top: 2.2rem;
      }
    }
  }
}

.section_3 {
  .section_content {
    padding-bottom: 0;
  }
  .advantage_list {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    .advantage_item {
      width: 25%;
      padding: 0.59%;
      .advantage_content {
        background-color: #f5f8fa;
        padding: 19.21% 15.24% 33.84% 14.94%;
        text-align: center;
        height: 100%;
      }
      .advantage_img {
        width: 79px;
        height: 79px;
      }
      .advantage_title {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-top: 19.21%;
      }
      .desc {
        text-align: left;
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 32px;
        color: #666666;
        margin-top: 14.33%;
      }
    }
  }
}

.section_4 {
  .customer_list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
    .customer_item {
      align-self: stretch;
      width: 20%;
      padding: 4px;
      overflow: hidden;
      @media screen and (max-width: 1200px) {
        width: 25%;
      }
      .bg_white {
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        padding: 11px 0;
        border: 1px solid #e1e1e1;
      }
      .customer_img {
        max-width: 32.71%;
        flex-shrink: 0;
      }
      .customer_title {
        margin-top: 4.89%;
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 29px;
        color: #262727;
      }
    }
  }
}

.section_5 {
  .section_content {
    padding-bottom: 0;
    font-size: 12;
  }
}

@media screen and (max-width: 992px) {
  .section_1 {
    background-color: #f5f8fa;
    flex-direction: column;
    padding: 0 10vpx 20vpx 10vpx;
    margin-top: 15vpx;
    .desc_img {
      width: 48vpx;
      height: 48vpx;
      margin: 20vpx 0;
    }
    .desc_text {
      margin-left: 0;
      font-size: 12vpx;
      line-height: 24vpx;
      color: #333333;
    }
  }

  .section_2 {
    margin-top: 40vpx;
    .why_img {
      height: 237vpx;
    }
    .title {
      padding-left: 0;
    }
    .section_content {
      display: block;

      .img_wrap {
        width: 100%;
      }
      .why_text {
        margin-left: 0;
        padding: 20vpx 0 20vpx 10vpx;
      }
      .why_title {
        font-size: 14vpx;
        font-weight: 500;
        line-height: normal;
      }

      .why_content {
        padding: 0;
        margin-top: 20vpx;
      }

      .crisis_list {
        font-size: 12vpx;
        line-height: normal;
        .crisis_item:not(:first-child) {
          margin-top: 20vpx;
        }
      }
    }
  }

  .section_3 {
    .title {
      padding-left: 0;
      padding-bottom: 30vpx;
    }
    .advantage_list {
      margin-top: -2.5vpx;
      margin-left: -2.5vpx;
      margin-right: -2.5vpx;
      .advantage_item {
        width: 50%;
        padding: 2.5vpx;
        .advantage_content {
          padding: 30vpx 15vpx 50vpx;
        }
        .advantage_img {
          width: 48vpx;
          height: 48vpx;
        }
        .advantage_title {
          font-size: 14vpx;
          font-weight: 500;
          margin-top: 30vpx;
        }
        .desc {
          font-size: 12vpx;
          line-height: 20vpx;
          margin-top: 15vpx;
        }
      }
    }
  }

  .section_4 {
    .title {
      padding-left: 0;
    }
    .customer_list {
      margin: -2.5vpx -2.5vpx 0 -2.5vpx;
      .customer_item {
        padding: 2.5vpx;
        width: 50%;
        .bg_white {
          padding: 8vpx 0;
        }
        .customer_img {
          max-width: 114vpx;
          max-height: 55vpx;
        }
        .customer_title {
          margin-top: 8vpx;
          font-size: 12vpx;
          line-height: normal;
        }
      }
    }
  }

  .section_5 {
    .title {
      padding-left: 0;
    }
  }
}
</style>